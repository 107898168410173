<template>
    <v-container fluid class="centered">
        <v-row id="top">
            <!-- <v-col cols="12" fluid class="pl-8 imgbg" style="position: absolute; top: 0px"> -->
            <v-col cols="12" fluid class="pl-8 imgbg">
                <v-row>
                    <v-col cols="6">
                        <h1 class="text-xs-h4 text-lg-h1 font-weight-black">
                            <v-row no-gutters>
                                <v-col cols="12" md="12">Your own</v-col>
                                <v-col cols="12" md="12">managed</v-col>
                                <v-col cols="12" md="12">network</v-col>
                                <v-col cols="12" md="12">black</v-col>
                                <v-col cols="12" md="12">hole</v-col>
                            </v-row>
                        </h1>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="9">
                        <div class="text-h6">
                            <br />{{ constants.app_name }} sends IP block lists to your network routers,
                            <br />implementing the "remotely-triggered routing black hole" (RTBH) technique,
                            <br />to protect your network from threats, spammers, scammers, botnets and more.
                        </div>
                    </v-col>
                    <v-col cols="12" md="3">
                        <div class="ma-4">
                            <v-btn x-large rounded href="/register" color="primary" target="_blank" class="text-none"><span class="">Start now <v-icon small>mdi-chevron-right</v-icon></span></v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row id="benefits">
            <v-col cols="12">
                <v-row>
                    <v-col cols="12" class="mt-16">
                        <div><h2 class="text-center text-h3 text-md-h2 mt-16 mb-16">Benefits of using {{ constants.app_name }}</h2></div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="3">
                        <div class="mb-4"><v-icon x-large>mdi-currency-usd</v-icon></div>
                        <div class="mb-3 text-h6">save resources and cost</div>
                        <div>
                            Change management is often slow and consumes valuable company resources. Keeping the filters on the edge of your network is a time consuming process. Automate this task by receiving filters over BGP and focus on what is important for your organization.
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <div class="mb-4"><v-icon x-large>mdi-format-list-checks</v-icon></div>
                        <div class="mb-3 text-h6">wide range of filters</div>
                        <div>
                            Available filters include networks identified as threats, spammers, TOR exit nodes, bogons, botnet C&amp;C nodes, whole countries, selected companies, ASNs, pornographic content and others.
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <div class="mb-4"><v-icon x-large>mdi-lock-outline</v-icon></div>
                        <div class="mb-3 text-h6">quality filter data</div>
                        <div>
                            Our filters are available in both IPv4 and IPv6 and they are updated daily. Special care is taken so that we never send you the default route or IP blocks that would compromise your network.
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <div class="mb-4"><v-icon x-large>mdi-timer-outline</v-icon></div>
                        <div class="mb-3 text-h6">easy setup</div>
                        <div>
                            The setup is quick and easy. Configuration templates are provided for most popular router
                            platforms including Cisco, Juniper and RouterBoard (Mikrotik).
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row id="how">
            <v-col cols="12">
                <v-row>
                    <v-col cols="12">
                        <div><h2 class="text-center text-h3 text-h2 mt-16 mb-16">How it works</h2></div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="3">
                        <div class="mb-4"><v-icon large>mdi-numeric-1-circle-outline</v-icon></div>
                        <div>
                            create a profile, select one or more filters that you want to receive and configure your
                            edge routers to establish BGP peering with our route servers
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <div class="mb-4"><v-icon large>mdi-numeric-2-circle-outline</v-icon></div>
                        <div>
                            Using BGP your edge routers receive prefixes from the filter(s) that you selected. The
                            received prefixes have a well-known BGP community "blackhole" attached to them (65535:666
                            per RFC 7999).
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <div class="mb-4"><v-icon large>mdi-numeric-3-circle-outline</v-icon></div>
                        <div>
                            Your edge routers automatically discard traffic destined for prefixes that they receive from
                            BGP Filters, effectively blocking all communication to those networks
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <div class="mb-4"><v-icon large>mdi-numeric-4-circle-outline</v-icon></div>
                        <div>
                            (optional) you can elevate {{ constants.app_name }} into a DDoS protection for your network
                            by applying uRPF (unicast reverse path forwarding) check and dropping traffic to/from the
                            networks listed in the filters you selected
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row id="pricing">
            <v-col class="text-center" cols="12">
                <h2 class="text-h3 text-md-h2">
                    <span class="text-no-wrap">Choose your plan</span> <span class="text-no-wrap">and start today</span>
                </h2>
                <div class="ma-4">
                    <span>
                        <v-btn
                            rounded
                            :elevation="sub_time_period === 'month' ? 2 : 0"
                            @click="sub_time_period = 'month'"
                            :outlined="sub_time_period === 'month'"
                            :text="sub_time_period !== 'month'"
                            >Month</v-btn
                        >
                        <v-btn
                            rounded
                            :elevation="sub_time_period === 'year' ? 2 : 0"
                            @click="sub_time_period = 'year'"
                            :outlined="sub_time_period === 'year'"
                            :text="sub_time_period !== 'year'"
                            >Year</v-btn
                        >
                    </span>
                </div>
                <div>
                    {{ subscription_period }}
                </div>
                <div class="d-inline-flex pa-2">
                    <Tier class="mx-3 flex-grow-0 flex-shrink-1" :tier="tiers[0]" :period="sub_time_period" />
                    <Tier class="mx-3 flex-grow-0 flex-shrink-1" :tier="tiers[1]" :period="sub_time_period" />
                    <Tier class="mx-3 flex-grow-0 flex-shrink-1" :tier="tiers[2]" :period="sub_time_period" />
                </div>
                <div class="mt-12 mx-auto" style="width: 50%">
                    Interested in more BGP peers, private peering servers in locations of your choice, on-prem instance,
                    API for integration with your IDS or more?
                </div>
                <div class="mt-4">
                    <v-btn
                        large
                        rounded
                        :href="`mailto:hello@${constants.domain}?subject=${constants.app_name} inquiry`"
                        color="primary"
                        target="_blank"
                        class="text-none"
                    >
                        <span class="">Contact us<v-icon right small>mdi-email-outline</v-icon></span>
                    </v-btn>
                </div>
                <v-snackbar v-model="cookieBanner" timeout="-1">
                    We use cookies to improve your experience on our site.
                    <a href="/privacy">Learn more.</a><v-btn @click="cookiesOK" class="ma-2" color="primary">OK</v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Tier from "@/components/Tier.vue";
import { useConstants } from "@/const/const.js";

import { ref, watch } from "@vue/composition-api";
import vuetify from "@/plugins/vuetify";

// import { useCookies } from "vue3-cookies";

import Cookies from "universal-cookie";

// import { useRoute } from "vue2-helpers/vue-router";

export default {
    props: {
        scrollTarget: {
            type: String,
            default: "top",
        },
    },
    components: {
        Tier,
    },
    setup(props) {
        // const route = useRoute();

        console.log("props");
        // console.log(props);

        const constants = useConstants();
        // const { cookies } = useCookies();
        const cookies = new Cookies();

        const cookieBanner = ref(true);

        if (cookies.get("cookiesOK") == 1) {
            cookieBanner.value = false;
        }

        const sub_time_period = "month";
        const tiers = [
            {
                name: "free",
                price_month: "0",
                price_year: "0",
                currency: "EUR",
                items: ["1 BGP peer", "1 BGP peering", "non-commercial use", "best effort support"],
                currencySymbol: "£",
                prefix: true,
                btnText: "Sign Up for Free",
                btnURL: "/register",
                color: vuetify.preset.theme.themes.dark.background,
                elevation: 4,
                btnDark: false,
                rel: "",
            },
            {
                name: "basic",
                price_month: "70",
                price_year: "500",
                currency: "EUR",
                items: ["5 BGP peers", "2 BGP sessions per peer", "NBD email support", "1 month free trial"],
                currencySymbol: "£",
                prefix: true,
                btnText: "Start free trial",
                btnURL: "/register?plan=basic",
                color: vuetify.preset.theme.themes.dark.primary,
                elevation: 16,
                btnDark: true,
                rel: "nofollow",
            },
            {
                name: "pro",
                price_month: "250",
                price_year: "2500",
                currency: "GBP",
                items: ["25 BGP peers", "4 BGP sessions per peer", "priority support", "1 month free trial"],
                currencySymbol: "£",
                prefix: true,
                btnText: "Start free trial",
                btnURL: "/register?plan=pro",
                color: vuetify.preset.theme.themes.dark.background,
                elevation: 4,
                btnDark: false,
                rel: "nofollow",
            },
        ];
        const subscription_period = ref("");

        setTimeout(() => {
            vuetify.framework.goTo("#" + props.scrollTarget);
        }, 1000);

        // https://stackoverflow.com/questions/59125857/how-to-watch-props-change-with-vue-composition-api-vue-3
        // Component props IS reactive, its attrs are not. This requires the following weird `watch` syntax
        // why is scollTarget passed as a prop through a router? For analytics. Plain scolling to target would not allow collecting metrics.
        watch(
            () => props.scrollTarget,
            (newValue) => {
                vuetify.framework.goTo("#" + newValue);
            }
        );

        function cookiesOK() {
            cookies.set("cookiesOK", 1);
            cookieBanner.value = false;
        }

        return {
            subscription_period,
            sub_time_period,
            tiers,
            constants,
            cookiesOK,
            cookieBanner,
        };
    },
};
</script>

<style scoped>

/* .mainContainer {
    width: 100%;
} */

.centered {
    margin-left: auto;
    margin-right: auto;
}

.imgbg {
    background-image: url("~@/assets/black-hole.jpg");
    /* background-size: contain; */
    /* background-size: cover; */
    background-size: 100%;
    /* background-position-x: 100px; */
    background-position-y: -100px;
}

</style>
