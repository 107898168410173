<template>
    <v-app :style="{ background: $vuetify.theme.themes.dark.background }" class="app">
        <v-app-bar app :style="{ background: $vuetify.theme.themes.dark.background }" class="app">
            <div>
                <a href="/" class="logoFont header-link">
                    <v-img
                        :alt="constants.app_name"
                        class="shrink mt-1 hidden-sm-and-down"
                        contain
                        min-width="100"
                        src=""
                        width="100"
                    />
                </a>
            </div>

            <v-spacer></v-spacer>
            <v-btn rounded @click="routerPush('/')" class="ms-2 text-none" text>Home</v-btn>
            <v-btn rounded @click="routerPush('/benefits')" class="ms-2 text-none" text
                >Why use {{ constants.app_name }}</v-btn
            >
            <v-btn rounded @click="routerPush('/how')" class="ms-2 text-none" text>How it works</v-btn>
            <v-btn rounded @click="routerPush('/pricing')" class="ms-2 text-none" text>Pricing</v-btn>
            <v-btn
                color="primary"
                rounded
                :href="`https://${constants.app_url}/login`"
                target="_blank"
                class="ms-2 text-none"
                ><span>Sign in <v-icon small>mdi-chevron-right</v-icon></span></v-btn
            >
        </v-app-bar>
        <v-main>
            <router-view />
        </v-main>
        <Footer class="pt-12" />
    </v-app>
</template>

<script>
import Footer from "@/components/Footer.vue";

import { useConstants } from "@/const/const.js";
import { useRouter } from "vue2-helpers/vue-router";
import { useRoute } from "vue2-helpers/vue-router";

const constants = useConstants();

export default {
    props: {},
    components: {
        Footer,
    },
    setup() {
        const router = useRouter();
        const route = useRoute();

        console.log("route");
        console.log(route);

        function routerPush(location) {
            router.push(location).catch((err) => {
                // Ignore the vuex err regarding  navigating to the page they are already on.
                if (
                    err.name !== "NavigationDuplicated" &&
                    !err.message.includes("Avoided redundant navigation to current location")
                ) {
                    // But print any other errors to the console
                    console.error(err);
                }
            });
        }

        return {
            constants,
            routerPush,
        };
    },
    metaInfo() {
        return {
            title: constants.app_name,
            titleTemplate: `%s | ${constants.web_url}`,
            htmlAttrs: {
                lang: "en-US",
            },
            meta: [
                { charset: "utf-8" },
                { name: "description", content: "Remotely-triggered black hole routing to protect your network from threats, spammers, botnets and more." },
                { name: "viewport", content: "width=device-width, initial-scale=1" },
                { name: "keywords", content: "network security, network black hole, BGP black hole, BGP blackhole, RTBH, Remotely-Triggered Blackhole Routing, IP block list, IP blocklist, IP block list over BGP" },
            ],
        };
    },
};
</script>

<style scoped>
.app {
    /* margin-left: auto; */
    /* margin-right: auto; */
    /* position: relative; */
    background-color: black;
}
</style>
