const constants = {
    app_name: "Network Black Hole",
    domain: "networkblackhole.com",
    web_url: "www.networkblackhole.com",
    app_url: "app.networkblackhole.com",
    analytics_url: "https://analytics.smartnetworkingsolutions.uk",
};

function useConstants() {
    return constants;
}

export { useConstants };
