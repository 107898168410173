import Vue from "vue";
import VueRouter from "vue-router";

import HomeView from "@/views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: HomeView,
    },
    {
        path: "/why",
        name: "why",
        component: HomeView,
        props: {
            scrollTarget: "why",
        },
    },
    {
        path: "/how",
        name: "how",
        component: HomeView,
        props: {
            scrollTarget: "how",
        },
    },
    {
        path: "/benefits",
        name: "benefits",
        component: HomeView,
        props: {
            scrollTarget: "benefits",
        },
    },
    {
        path: "/pricing",
        name: "pricing",
        component: HomeView,
        props: {
            scrollTarget: "pricing",
        },
    },
    {
        path: "/terms",
        name: "terms",
        component: () => import("@/views/TermsView.vue"),
    },
    {
        path: "/privacy",
        name: "privacy",
        component: () => import("@/views/PrivacyView.vue"),
    },
    {
        path: "/register",
        name: "register",
        component: () => import("@/views/RegisterView.vue"),
    },
    {
        path: "/contact",
        name: "contact",
        component: () => import("@/views/ContactView.vue"),
    },
    {
        path: "/status",
        name: "status",
        component: () => import("@/views/StatusView.vue"),
    },
    {
        path: "/support",
        name: "support",
        component: () => import("@/views/SupportView.vue"),
    },
    {
        path: "*",
        name: "Not Found",
        component: () => import("@/views/NotFoundView.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

// router.beforeEach((to, from) => {
//     console.log("router");
//     console.log(to);
//     console.log(from);
// });

export default router;
