import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

// import colors from 'vuetify/lib/util/colors'

export default new Vuetify({
    theme: {
        themes: {
            dark: {
                // primary: colors.blue,
                // background: '#aa0000',
                background: "#000000",
            },
        },
        dark: true,
        options: {
            customProperties: true,
        },
    },
});
