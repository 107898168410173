<template>
    <v-card
        class="pa-4 container"
        raised
        :elevation="tier.elevation"
        :color="tier.color"
        max-width="300"
        min-width="260"
        fill-height
    >
        <div class="text-h4 center">{{ tier.name }}</div>
        <div class="text-h1 mt-6 center">
            <span v-if="tier.prefix" class="text-h3">{{ tier.currencySymbol }}</span>
            <span v-if="period === 'month'">{{ tier.price_month }}</span>
            <span v-if="period === 'year'">{{ tier.price_year }}</span>
            <span v-if="!tier.prefix">{{ tier.currencySymbol }}</span>
            <!-- <span v-if="period === 'month'" class="text-subtitle-2 text-no-wrap">/month</span> -->
            <!-- <span v-if="period === 'year'" class="text-subtitle-2 text-no-wrap">/year</span> -->
        </div>
        <div style="height: 20px">
            <span v-if="period === 'year' && tier.price_year > 0" class="text-subtitle-2">(2 months for free)</span>
        </div>
        <div class="text-subtitle-2 mt-2">
            <v-list :style="{ background: tier.color }" dense>
                <v-list-item v-for="item in tier.items" :key="item" tabindex="">
                    <v-icon left>mdi-router</v-icon>
                    {{ item }}
                </v-list-item>
            </v-list>
        </div>
        <div>
            <v-btn
                rounded
                :dark="tier.btnDark"
                :color="tier.btnDark ? 'background' : 'primary'"
                bottom
                :href="tier.btnURL"
                class="text-none my-4"
                min-width="150"
                large
                :rel="tier.rel"
            >
                {{ tier.btnText }}
            </v-btn>
        </div>
    </v-card>
</template>

<script>
import vuetify from "@/plugins/vuetify";

export default {
    name: "Tier",
    props: ["tier", "period"],

    setup() {
        return {
            primary_color: vuetify.preset.theme.themes.dark.primary,
        };
    },
};
</script>

<style scoped>
.container {
    border-style: solid;
    border-width: 3px;
    border-color: var(--v-primary-base) !important;
}

.center {
    text-align: center;
}

.v-list-item {
    min-height: 35px !important;
    padding: 0 8px;
}
</style>
