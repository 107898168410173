<template>
    <v-bottom-navigation
        app
        absolute
        hide-on-scroll
        :scroll-threshold="0"
        height="80"
        max-height="80"
        class="ma-0 pa-0"
        :style="{ background: $vuetify.theme.themes.dark.background }"
        mode="horizontal"
    >
        <v-container fluid class="footerHeight">
            <v-row>
                <v-col cols="12" sm="6" md="6">
                    <v-card-text class="pa-0">
                        <div>
                            &copy; {{ new Date().getFullYear() }}
                            <strong><a href="https://www.smartnetworkingsolutions.uk">Smart Networking Solutions</a></strong> Ltd
                        </div>
                        <div>All rights reserved.</div>
                    </v-card-text>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-row>
                        <v-col cols="6" md="4" lg="4">
                            <v-card-text class="pa-0">
                                <div>
                                    <a :href="`/terms`" class="footer-link"><strong>Terms of Service</strong></a>
                                </div>
                                <div>
                                    <a :href="`/privacy`" class="footer-link"><strong>Privacy Policy</strong></a>
                                </div>
                            </v-card-text>
                        </v-col>
                        <v-col cols="6" md="4" lg="4">
                            <v-card-text class="pa-0">
                                <div>
                                    <!-- <a :href="`mailto:hello@${constants.domain}`" class="footer-link"><strong>Contact</strong></a> -->
                                    <a :href="`/contact`" class="footer-link"><strong>Contact</strong></a>
                                </div>
                                <div>
                                    <a :href="`/status`" class="footer-link"><strong>System Status</strong></a>
                                </div>
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-bottom-navigation>
</template>

<script>
import { useConstants } from "@/const/const.js";

export default {
    setup() {
        const constants = useConstants();

        const name = "Footer";
        return {
            name,
            constants,
        };
    },
};
</script>

<style scoped>
.footerHeight {
    height: 75px;
}

a.footer-link:link,
a.footer-link:visited {
    /* color: rgba(0, 0, 0, 0.67); */
    text-decoration: none;
}
</style>
